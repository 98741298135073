import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'

Vue.config.productionTip = false

import i18n from './components/mixins/i18n.vue';
Vue.mixin(i18n);

// Install BootstrapVue
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/fontawesome/css/all.css'
import './assets/css/style.css'

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

new Vue({
  render: h => h(App),
  store,
  router,
  Axios,
  data:function (){
    return {
      api_url: process.env.VUE_APP_API_URL,
      app_name: process.env.VUE_APP_NAME,
      global_settings:null
    }
  },
  methods:{
    get_global_settings:function (){
      Axios({url: this.$root.$data.api_url + '/api/guest/settings', params: {}, method: 'GET'})
          .then(resp => {
            this.global_settings = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
      this.get_global_settings()
  }
}).$mount('#app')
