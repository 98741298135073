import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        lang: localStorage.getItem('lang') || 'en',
    },
    mutations: {
        SET_LANG(state, payload) {
            state.lang = payload
        },

    },
    actions: {
        setLang({commit}, lang) {
            commit('SET_LANG', lang)
            localStorage.setItem('lang', lang)
        }
    },
    getters: {
        lang: state => state.lang,
    }
})