<template>
  <div class="deposit-form deposit-momo-form">
    <div v-if="transaction && deposit_account" class="deposit-form-content">
      <!-- Alert -->
      <div v-if="transaction.status == 1" class="process-alert-content-wap">
        <div class="process-alert-content success">
          <div class="icon">
            <span class="fas fa-check"></span>
          </div>
          <div class="message">
            {{ $trans('Transaction is successful. Thanks for using the service!') }}
          </div>
        </div>
      </div>
      <div v-if="transaction.status < 0 " class="process-alert-content-wap">
        <div class="process-alert-content danger">
          <div class="icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <div class="message">
            {{ $trans('Transaction failed') }}. {{ $trans(deposit_status[transaction.status]) }}
          </div>
        </div>
      </div>
      <div class="form">
        <div class="header">
          <h2>{{ $trans('Momo transaction') }}</h2>
          <span class="time">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12"
                                                                                           r="10"></circle><polyline
                points="12 6 12 12 16 14"></polyline></svg>
            <span class="text">{{ timeLeft }}</span>
          </span>
        </div>
        <div class="body">
          <!-- Stepers Wrapper -->
          <div class="processs-info">
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Trading code') }}:</label>
              <strong class="ref-number">{{ transaction.ref_number }}</strong>
            </div>
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Transaction time') }}:</label>
              <strong>{{ transaction.created_at }}</strong>
            </div>
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Transaction amount') }}:</label>
              <strong class="text-uppercase">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="currency"
                    v-bind:value="transaction.amount"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </strong>
            </div>
          </div>
          <div class="main-content">
            <div class="row">
              <div class="col-sm-5">
                <div class="bank-logo mb-3">
                  <div v-if="qr_text!='' "
                       class="qr mr-3">
                    <qr-code
                        :text="qr_text"
                        :size="350"
                        color="#000000"
                        bg-color="#ffffff"
                        error-level="L">
                    </qr-code>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <h6 class="mb-3">Thực hiện các bước sau để tiến hành thanh toán</h6>
                <p><strong>Bước 1:</strong> Đăng nhập ứng dụng MoMo trên thiết bị di động</p>
                <p><strong>Bước 2:</strong> Chọn QUÉT MÃ ở góc phải trên cùng của ứng dụng và tiến hành
                  quét QR Code bên cạnh</p>
                <p><strong>Bước 3:</strong> Nhập số tiền là: <strong>
                  <vue-numeric currency-symbol-position="suffix" currency="VND"
                               v-bind:value="transaction.amount" v-bind:read-only="true"
                               v-bind:precision="0"></vue-numeric>
                </strong> và nội dung chuyển tiền là: <strong
                    class="ref-number">{{transaction.ref_number}}</strong></p>
                <table class="table table-striped">
                    <tbody>
                    <tr>
                      <th>Số TK:</th>
                      <td>
                          {{deposit_account.account_number}}
                      </td>
                      <td>
                        <a @click="doCopy(deposit_account.account_number)" href="javascript:void(0)">
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                        </a>
                      </td>
                    </tr>
                    <!--<tr>
                      <th>Chủ TK:</th>
                      <td>
                        {{deposit_account.account_name}}
                      </td>
                      <td>
                        <a @click="doCopy(deposit_account.account_name)" href="javascript:void(0)">
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                        </a>
                      </td>
                    </tr>-->
                      <tr>
                        <th>Số tiền:</th>
                        <td>
                          <vue-numeric currency-symbol-position="suffix" currency="VND"
                                       v-bind:value="transaction.amount" v-bind:read-only="true"
                                       v-bind:precision="0"></vue-numeric>
                        </td>
                        <td>
                          <a @click="doCopy(transaction.amount)" href="javascript:void(0)">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>Nội dung:</th>
                        <td><strong class="ref-number">{{transaction.ref_number}}</strong></td>
                        <td>
                          <a @click="doCopy(transaction.ref_number)" href="javascript:void(0)">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                </table>
                <p><strong>Bước 4:</strong> Bấm xác nhận trên ví và hoàn tất.</p>

                <p><strong>Bước 5:</strong> Sau khi chuyển khoản vui lòng bấm vào "Xác nhận thanh toán"
                  bên dưới.</p>
                <a v-bind:href="transaction.return_url" class="btn btn-primary btn-round btn-block">Xác nhận đã chuyển</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <ul class="list-note">
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Please transfer before confirming') }}
            </li>
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Make sure the correct text of the transfer is') }}: <strong>{{transaction.ref_number}}</strong>
            </li>
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Save the transaction code for comparison when necessary') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="process-alert">
      <div class="process-alert-content">
        <div class="icon">
          <span class="fas fa-info"></span>
        </div>
        <div class="message">
          {{ $trans('The system is initiating the transaction. Please wait a second. Thanks!') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";
export default {
  name: "Momo",
  props: {
    currency: String,
    transaction_id: String,
  },
  data:function (){
    return {
      transaction:null,
      deposit_account:null,
      timeLeft: '00:00',
    }
  },
  methods:{
    index: function () {
      axios({
        url: this.$root.$data.api_url + '/api/guest/deposit/' + this.transaction_id,
        params: this.filterQuery,
        method: 'GET',
        headers: {
          currency: this.currency.toUpperCase()
        }
      })
          .then(resp => {
            let transaction = resp.data
            if( transaction.method !='MOMO'){
              this.$router.push('/404')
            }
            this.transaction = transaction
            this.deposit_account = resp.data.deposit_account;
            this.countdown(resp.data.created_at);

          })
          .catch(err => {
            console.log(err)
          })
    },
    doCopy:function (text){
      this.$copyText(text).then(function (e) {
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
    countdown: function (time) {
      var oldDateObj = new Date(time);
      var newDateObj = new Date(time);
      newDateObj.setTime(oldDateObj.getTime() + (10 * 60 * 1000));
      // Set the date we're counting down to
      var countDownDate = newDateObj.getTime();
      var intervalTimer = setInterval(() => {
        var vnTime = new Date().toLocaleString("en-US", {timeZone: this.timezone});
        var now = new Date(vnTime).getTime();
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        function zeroPadded(num) {
          return num < 10 ? '0' + num : num;
        }

        this.timeLeft = zeroPadded(minutes) + ':' + zeroPadded(seconds);
        if (distance <= 0) {
          clearInterval(intervalTimer);
         //this.stopJob('transaction_time_is_over')
        }
      }, 1000);
    },
  },
  created() {
    let lang = 'vi'
    switch (this.currency) {
      case 'vnd':
        lang = 'vi'
        break;
      case 'thb':
        lang = 'th'
        break;
      case 'idr':
        lang = 'id'
        break;
      default:
        lang = 'en'
        break
    }
    this.$store.dispatch('setLang', lang)
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );

    //  Deposit channel
    const channel2 = pusher.subscribe('deposit-channel');
    channel2.bind('deposit-event', (e) => {
      let current_currency = this.currency.toUpperCase();
      if (current_currency == e.currency && this.transaction._id == e.transaction._id) {
        this.transaction = e.transaction;
      }
    });
  },
  computed:{
      qr_text:function (){
          if( !this.deposit_account){
              return  '';
          }
          let deposit_account = this.deposit_account
          let transaction = this.transaction
          return '2|99|' + deposit_account.account_number + '|' + deposit_account.account_name + '|' + deposit_account.account_email + '|0|0|' + transaction.amount + '';
      },
      deposit_status: function () {
        return this.$root.$data.global_settings.deposit_status
      },
      timezone: function () {
        return this.$root.$data.global_settings.timezone
      },
  }
}
</script>

<style scoped>

</style>