<template>
<div class="deposit-form">
  <div v-if="transaction && deposit_account" class="deposit-form-content">
    <!-- Alert -->
    <div v-if="transaction.status == 1" class="process-alert-content-wap">
      <div class="process-alert-content success">
        <div class="icon">
          <span class="fas fa-check"></span>
        </div>
        <div class="message">
          {{ $trans('Transaction is completed. Thanks for using the service!') }}
        </div>
      </div>
    </div>
    <div v-if="transaction.status < 0 " class="process-alert-content-wap">
      <div class="process-alert-content danger">
        <div class="icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div class="message">
          {{ $trans('Transaction failed') }}. {{ $trans(deposit_status[transaction.status]) }}
        </div>
      </div>
    </div>
    <div class="form">
      <div class="header">
        <h2>{{ $trans('Crypto transaction') }}</h2>
      </div>
      <div class="body">
        <!-- Stepers Wrapper -->
        <div class="processs-info">
          <div class="processs-info-item">
            <label class="d-block">{{ $trans('Trading code') }}:</label>
            <strong class="ref-number">{{ transaction.ref_number }}</strong>
          </div>
          <div class="processs-info-item">
            <label class="d-block">{{ $trans('Transaction time') }}:</label>
            <strong>{{ transaction.created_at }}</strong>
          </div>
          <div class="processs-info-item">
            <label class="d-block">{{ $trans('Transaction amount') }}:</label>
            <strong class="text-uppercase">
              <vue-numeric
                  currency-symbol-position="suffix"
                  :currency="currency"
                  v-bind:value="transaction.amount"
                  v-bind:read-only="true"
                  v-bind:precision="6">
              </vue-numeric>
            </strong>
          </div>
        </div>
        <div class="main-content">
          <div class="row">
            <div class="col-sm-4">
              <div class="bank-logo mb-3">
                @
              </div>
            </div>
            <div class="col-sm-8">
              <div class="alert alert-info">
                {{$trans('Please transfer the amount to our account with the following information')}}
              </div>
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th>{{$trans('Wallet Address')}}:</th>
                  <td>{{deposit_account.wallet_address}}</td>
                  <td>
                    <a @click="doCopy(deposit_account.wallet_address)" href="javascript:void(0)">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="mb-3">
                {{$trans('After making the transfer please click "Confirm Payment" below.')}}
              </div>
              <a :href="transaction.return_url" class="btn btn-primary btn-block">{{$trans('Confirm Payment')}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <ul class="list-note">
          <li>
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            {{ $trans('Please transfer before confirming') }}
          </li>
          <li>
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            {{ $trans('Make sure the correct text of the transfer is') }}: <strong>{{transaction.ref_number}}</strong>
          </li>
          <li>
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            {{ $trans('Save the transaction code for comparison when necessary') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="process-alert">
    <div class="process-alert-content">
      <div class="icon">
        <span class="fas fa-info"></span>
      </div>
      <div class="message">
        {{ $trans('The system is initiating the transaction. Please wait a second. Thanks!') }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "Crypto",
  props: {
    currency: String,
    transaction_id: String,
  },
  data:function (){
    return {
      transaction:null,
      deposit_account:null
    }
  },
  methods:{
    index: function () {
      axios({
        url: this.$root.$data.api_url + '/api/guest/deposit/' + this.transaction_id,
        params: this.filterQuery,
        method: 'GET',
        headers: {
          currency: this.currency.toUpperCase()
        }
      })
          .then(resp => {
            let transaction = resp.data
            if( transaction.method !='CRYPTO'){
              this.$router.push('/404')
            }
            this.transaction = transaction
            this.deposit_account = resp.data.deposit_account;

          })
          .catch(err => {
            console.log(err)
          })
    },
    doCopy:function (text){
      this.$copyText(text).then(function (e) {
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
  },
  created() {
    this.index();
  },
}
</script>

<style scoped>

</style>