<template>
  <div class="deposit-form ib-form">
    <div v-if="transaction && deposit_job" class="deposit-form-content">

      <!-- Alert -->
      <div v-if="transaction.status == 1 || transaction.status == 0" class="process-alert-content-wap">
        <div class="process-alert-content success">
          <div class="icon">
            <span class="fas fa-check"></span>
          </div>
          <div class="message">
            {{ $trans('Transaction is completed. Thanks for using the service!') }}
          </div>
        </div>
      </div>
      <div v-if="transaction.status < 0 " class="process-alert-content-wap">
        <div class="process-alert-content danger">
          <div class="icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <div class="message">
            {{ $trans('Transaction failed') }}. {{ $trans(deposit_status[transaction.status]) }}<br>
            <slot v-if="message">{{message}}</slot>
          </div>
        </div>
      </div>
      <div v-if="show_app_notification" class="process-alert-content-wap">
        <div class="process-alert-content info">
          <div class="icon">
            <span class="fas fa-info"></span>
          </div>
          <div class="message">
            Quý khách vui lòng truy cập ứng dụng VCB Digibank trên thiết bị di động để xác nhận đăng nhập
          </div>
        </div>
      </div>
      <div class="form">
        <div class="header">
          <h2>{{ $trans('Transfer transaction') }}</h2>
          <span class="time">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12"
                                                                                           r="10"></circle><polyline
                points="12 6 12 12 16 14"></polyline></svg>
            <span class="text">{{ timeLeft }}</span>
          </span>
        </div>
        <div class="body">
          <!-- Stepers Wrapper -->
          <div class="processs-info">
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Trading code') }}:</label>
              <strong class="ref-number">{{ transaction.ref_number }}</strong>
            </div>
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Transaction time') }}:</label>
              <strong>{{ transaction.created_at }}</strong>
            </div>
            <div class="processs-info-item">
              <label class="d-block">{{ $trans('Transaction amount') }}:</label>
              <strong class="text-uppercase">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="currency"
                    v-bind:value="transaction.amount"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </strong>
            </div>
          </div>
          <ul class="stepper stepper-horizontal mb-2">
            <!-- First Step -->
            <li class="completed">
              <a href="javascript:void(0);">
                <span class="circle">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path
                      d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                </span>
                <span class="label">{{ $trans('Login') }}</span>
              </a>
            </li>
            <!-- Second Step -->
            <li>
              <a v-bind:class="{completed:(deposit_job.step == 2 || deposit_job.step == 3)}" href="javascript:void(0);">
                <span class="circle">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path
                      d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
                </span>
                <span class="label">{{ $trans('Verification code') }}</span>
              </a>
            </li>
            <!-- Third Step -->
            <li>
              <a href="javascript:void(0);">
                <span class="circle">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline
                      points="20 6 9 17 4 12"></polyline></svg>
                </span>
                <span class="label">{{ $trans('Finish') }}</span>
              </a>
            </li>

          </ul>
          <div class="main-content">
            <slot v-if="deposit_job.bank == 'VND_VCB'">
              <div class="alert alert-info text-left">
                <small><strong>THÔNG BÁO:</strong><br>
                  Để tiên hành thao tác thực hiện giao dịch trên trình duyệt web, vui lòng xác nhận theo hướng dẫn:
                  <strong>Đăng nhập ứng dụng VCB Digibank >> Cài đặt >> Cài đặt chung >> Cài đặt đăng nhập >> <strong style="color: red;">TẮT</strong> lựa chọn "Cài đặt đăng nhập VCB Digibank trên trình duyệt web".</strong>
                  Chúng tôi xin lỗi vì các bất tiện (nếu có) đối với Quý khách.
                  Trân trọng cảm ơn.</small>
              </div>
            </slot>
            <div class="row">
              <div class="col-sm-5">
                <div class="bank-logo">
                  <img :src="require('../assets/images/bank_logo/'+deposit_job.bank+'.png')" :alt="deposit_job.bank">
                </div>
              </div>
              <div class="col-sm-7">
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                  {{ alert.message }}
                </div>
                <div v-if="message" class="alert alert-warning">{{message}}</div>
                <div v-if="show_login_from" class="form-login">
                  <div class="form-group">
                    <label>{{ $trans('Username') }}</label>
                    <input v-model="username" class="form-control" type="text">
                    <slot v-if="errors && errors['username']">
                      <div class="text-danger" v-for="error in errors['username']"
                           v-bind:key="error">
                        <small>{{ error }}</small>
                      </div>
                    </slot>
                  </div>
                  <div class="form-group">
                    <label>{{ $trans('Password') }}</label>
                    <input v-model="password" class="form-control" type="password">
                    <slot v-if="errors && errors['password']">
                      <div class="text-danger" v-for="error in errors['password']"
                           v-bind:key="error">
                        <small>{{ error }}</small>
                      </div>
                    </slot>
                  </div>
                  <button @click="login" class="btn btn-primary btn-block">{{ $trans('Continue') }}</button>
                </div>
                <div v-if="show_otp_from" class="form-otp">
                  <div class="bidv-form-otp" v-if="deposit_job.bank=='VND_BIDV' && deposit_job.otp_type=='smart_otp'">
                    <div class="top">
                      <div v-if="deposit_job.qr_image && deposit_job.qr_image!=''"  class="qr mr-1">
                        <img :src="deposit_job.qr_image" alt="">
                      </div>
                      <div class="otp-note">
                        <p>Giao dịch đang chờ xác thực bằng phương thức Smart OTP trên ứng dụng SmartBanking trên điện thoại của Quý khách.</p>
                        <p>Hiệu lực giao dịch là 1 phút</p>
                        <p>Quý khách vui lòng <strong style="color: red;">KHÔNG</strong> tắt trình duyệt cho đến khi nhận được kết quả giao dịch trên Internet Banking. Xin cảm ơn!</p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="mr-3">
                        <img  style="width: 200px;" :src="require('../assets/images/bidv_hd.png')" alt="">
                      </div>

                      <div>
                        <strong>Hướng dẫn xác thực:</strong>
                        <ol style="padding-left: 15px;">
                          <li>Mở ứng dụng và KHÔNG thực hiện đăng nhập</li>
                          <li>Chọn chức năng Smart OTP</li>
                          <li>Quét QR trên đây và xác thực giao dịch</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <slot v-else>
                    <div v-if="deposit_job.smart_otp_number!=''  && deposit_job.bank=='VND_TCB'"
                         class="qr mr-3">
                      <qr-code
                          :text="deposit_job.smart_otp_number"
                          :size="150"
                          color="#000000"
                          bg-color="#ffffff"
                          error-level="L">
                      </qr-code>
                    </div>
                    <div v-if="deposit_job.qr_image && deposit_job.qr_image!=''" class="qr mr-3">
                      <img :src="deposit_job.qr_image" alt="">
                    </div>
                  <div class="form-otp-content">
                    <div class="mb-2" v-if="deposit_job.smart_otp_number">
                      <slot v-if="currency =='vnd'">{{ $trans('Trading code') }}:</slot>
                      <strong>{{ deposit_job.smart_otp_number }}</strong>
                    </div>
                    <div class="form-group">
                      <input :placeholder="$trans('Verification code')" v-model="otp" class="form-control" type="text">
                      <slot v-if="errors && errors['otp']">
                        <div class="text-danger" v-for="error in errors['otp']"
                             v-bind:key="error">
                          <small>{{ error }}</small>
                        </div>
                      </slot>
                    </div>
                    <button @click="update_otp" class="btn btn-primary btn-block">{{ $trans('Continue') }}</button>
                    <div v-if="deposit_job.bank=='VND_TCB'" class="mt-3">
                      <ul class="hd">
                        <li>
                          <strong>Bước 1: </strong>
                          Mở ứng dụng Fast Mobile, Tại màn hình đăng nhập<br> → Bấm vào nút
                          <img width="100" :src="require('../assets/images/TCB_HD_GET_OTP.png')" alt="TCB_HD_GET_OTP">
                        </li>
                        <li>
                          <strong>Bước 2:</strong>
                          Trên ứng dụng Fast Mobile, nhập mã giao dịch hoặc quét QR code bên cạnh,
                          bấm "<strong>Tiếp tục</strong>"
                        </li>
                        <li>
                          <strong>Bước 3:</strong>
                          Nhập mã mở khóa, Quý Khách sẽ nhận được mã OTP trên F@st Mobile.(Mã mở
                          khóa đã được tạo trong quá trình đăng ký Smart OTP)
                        </li>
                        <li>
                          <strong>Bước 4:</strong>
                          Nhập mã <strong>OTP</strong> vừa nhận được vào ô phía trên để xác thực
                          cho giao dịch.
                        </li>
                      </ul>
                    </div>
                    <div v-if="deposit_job.bank=='VND_MBB' && deposit_job.otp_type && deposit_job.otp_type=='smart_otp'" class="mt-3">
                      <strong class="d-block mb-2">Hướng dẫn xác thực giao dịch</strong>
                      <ul class="hd">
                        <li>
                          <strong>Bước 1: </strong>
                          Sử dụng App ngân hàng MBBank trên thiết bị đã đăng ký Digital OTP
                        </li>
                        <li>
                          <strong>Bước 2:</strong>
                          Chọn xác thực giao dịch tại màn hình Đăng nhập
                        </li>
                        <li>
                          <strong>Bước 3:</strong>
                          Scan mã QR code để nhận mã OTP
                        </li>
                        <li>
                          <strong>Bước 4:</strong>
                          Nhập mã <strong>OTP</strong> vừa nhận được vào ô phía trên để xác thực
                          cho giao dịch.
                        </li>
                      </ul>
                    </div>
                  </div>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <ul class="list-note">
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Make sure your account has been registered with internet banking') }}
            </li>
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Make sure your account balance is enough for the transaction') }}
            </li>
            <li class="ban">
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <path
                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              {{ $trans('DO NOT refresh or exit the browser while the transaction is in progress') }}
            </li>
            <li>
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              {{ $trans('Save the transaction code for comparison when necessary') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="process-alert">
      <div class="process-alert-content">
        <div class="icon">
          <span class="fas fa-info"></span>
        </div>
        <div class="message">
          {{ $trans('The system is initiating the transaction. Please wait a second. Thanks!') }}
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="note">
        {{
          $trans('Note: The transaction will fail if the browser is turned off while the transaction is being processed.')
        }}
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "InternetBanking",
  props: {
    currency: String,
    transaction_id: String,
  },
  data: function () {
    return {
      transaction: null,
      filterQuery: {},
      process: false,
      errors: {},
      deposit_job: null,
      username: '',
      password: '',
      otp: '',
      alert: null,
      timeLeft: '00:00',
      message: null
    }
  },
  methods: {
    index: function () {
      axios({
        url: this.$root.$data.api_url + '/api/guest/deposit/' + this.transaction_id,
        params: this.filterQuery,
        method: 'GET',
        headers: {
          currency: this.currency.toUpperCase()
        }
      })
          .then(resp => {
            let transaction = resp.data
            let list_sttaus = [2, 3];
            let is = list_sttaus.includes(transaction.status);
            if (is == false || !transaction) {
              this.$router.push('/404')
            }
            this.transaction = transaction;
            this.deposit_job = resp.data.deposit_job;
            this.countdown(resp.data.created_at);
          })
          .catch(err => {
            console.log(err)
          })
    },
    login: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let job_id = ''
        if (this.deposit_job) {
          job_id = this.deposit_job._id
        }
        let data = {
          job_id: job_id,
          username: this.username,
          password: this.password,
          deposit_id: this.transaction._id
        }
        axios({
          url: this.$root.$data.api_url + '/api/guest/deposit/login',
          data: data,
          method: 'POST',
          headers: {
            currency: this.currency.toUpperCase(),
            language: localStorage.getItem('lang')
          }
        })
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.deposit_job = resp.data.data;
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    update_otp: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let job_id = ''
        if (this.deposit_job) {
          job_id = this.deposit_job._id
        }
        let data = {
          job_id: job_id,
          otp: this.otp,
        }
        axios({
          url: this.$root.$data.api_url + '/api/guest/deposit/update_otp',
          data: data,
          method: 'POST',
          headers: {
            currency: this.currency.toUpperCase(),
            language: localStorage.getItem('lang')
          }
        })
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.deposit_job = resp.data.data;
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    countdown: function (time) {
      var oldDateObj = new Date(time);
      var newDateObj = new Date(time);
      newDateObj.setTime(oldDateObj.getTime() + (this.ib_deposit_time * 60 * 1000));
      // Set the date we're counting down to
      var countDownDate = newDateObj.getTime();
      var intervalTimer = setInterval(() => {
        var vnTime = new Date().toLocaleString("en-US", {timeZone: this.timezone});
        var now = new Date(vnTime).getTime();
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        function zeroPadded(num) {
          return num < 10 ? '0' + num : num;
        }

        this.timeLeft = zeroPadded(minutes) + ':' + zeroPadded(seconds);
        if (distance <= 0) {
          clearInterval(intervalTimer);
          this.stopJob('transaction_time_is_over')
        }
      }, 1000);
    },
    redirectApp: function () {
      setTimeout(() => {
        window.location.href = this.transaction.return_url;
      }, 5000)
    },
    stopJob: function (status) {
      this.process = true;
      this.errors = {}
      this.alert = null
      let job_id = ''
      if (this.deposit_job) {
        job_id = this.deposit_job._id
      }
      let data = {
        job_id: job_id,
        deposit_id: this.transaction._id,
        status: status
      }
      axios({
        url: this.$root.$data.api_url + '/api/guest/deposit/stop',
        data: data,
        method: 'POST',
        headers: {
          currency: this.currency.toUpperCase(),
          language: localStorage.getItem('lang')
        }
      })
          .then(resp => {
            let error_code = resp.data.error_code;
            if (error_code == 0) {
              this.deposit_job = resp.data.data;
              this.redirectApp();
            } else {
              this.alert = {
                type: 'warning',
                message: resp.data.message
              }
              this.errors = resp.data.errors || {};
            }
            this.process = false;
          })
          .catch(err => {
            console.log(err)
            this.process = false;
          })
    }
  },
  created() {
    let lang = 'vi'
    switch (this.currency) {
      case 'vnd':
        lang = 'vi'
        break;
      case 'thb':
        lang = 'th'
        break;
      case 'idr':
        lang = 'id'
        break;
        default:
          lang = 'en'
          break
    }
    this.$store.dispatch('setLang', lang)
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );

    const channel = pusher.subscribe('deposit-job-channel');

    channel.bind('deposit-job-event', (e) => {
      if (this.deposit_job && this.deposit_job._id == e.deposit_job._id) {
        this.deposit_job = e.deposit_job
        this.message = e.message
        this.otp =''
        if (e.deposit_job.login_count >= 2) {
          this.stopJob('wrong_login_information');
        }
      }

    });
    //  Deposit channel
    const channel2 = pusher.subscribe('deposit-channel');
    channel2.bind('deposit-event', (e) => {
      let current_currency = this.currency.toUpperCase();
      if (current_currency == e.currency && this.transaction._id == e.transaction._id) {
        this.transaction = e.transaction;
        let list_sttaus = [2, 3];
        let is = list_sttaus.includes(e.transaction.status);
        if (is == false) {
          this.$router.redirectApp();
        }
      }
    });
  },
  computed: {
    show_login_from: function () {
      if (!this.deposit_job) {
        return true;
      }
      let deposit_job = this.deposit_job;
      if (deposit_job.step == 0) {
        return true;
      }
      return false;
    },
    show_otp_from: function () {
      if (this.deposit_job) {
        let deposit_job = this.deposit_job;
        if (deposit_job.step == 2) {
          return true;
        }
      }
      return false
    },
    loading: function () {
      if (this.deposit_job) {
        if (this.show_app_notification){
            return  false
        }
        let deposit_job = this.deposit_job;
        let transaction = this.transaction;
        if (transaction.status == 2 || transaction.status == 3) {
          if (deposit_job.step == 1 || deposit_job.step == 3) {
            return true;
          }
        }
      }
      return false;
    },
    timezone: function () {
      return this.$root.$data.global_settings.timezone
    },
    deposit_status: function () {
      return this.$root.$data.global_settings.deposit_status
    },
    ib_deposit_time:function (){
      return this.$root.$data.global_settings.ib_deposit_time
    },
    show_app_notification:function (){
      if (this.deposit_job && this.transaction) {
        if (this.deposit_job.step == 1 && (this.transaction.status == 2 || this.transaction.status == 3) && this.deposit_job.app_request && this.deposit_job.app_request==1){
            return  true
        }
      }
      
      return false;
    }
  },

}

</script>

<style scoped>

</style>