import { render, staticRenderFns } from "./Zalo.vue?vue&type=template&id=5ebd3e9a&scoped=true&"
import script from "./Zalo.vue?vue&type=script&lang=js&"
export * from "./Zalo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ebd3e9a",
  null
  
)

export default component.exports