<template>
  <div id="app">
        <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data:function (){
    return {
        transaction:{}
    }
  },
  methods:{
      index:function (){

      }
  },
  created() {
    this.index()
  }
}
</script>

<style>

</style>
